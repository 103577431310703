import React from "react"
import { css } from "@emotion/core"
import SEO from "../components/SEO"
import Layout from "../components/Layout"
import Header from "../components/Header"
import NextPage from "../components/NextPage"
import MikeyAriText from "../components/MikeyAriText"
import MukeAriImage from "../components/MikeyAriImage"
import { fonts, colors, animations } from "../theme"

const inviteLockup = css`
  display: flex;
  max-width: 980px;
  margin: 50px auto;
`

const visualContainer = css`
  position: absolute;
  top: 20px;
  left: 20px;
  width: 200px;
  margin-right: -60px;

  @media (min-width: 575px) {
    position: relative;
    width: 370px;
  }

  @media (min-width: 775px) {
    width: 570px;
  }
`
const visualText = css`
  position: absolute;
  top: 0.375em;
  right: 0;
  left: 0;
  animation-name: ${animations.rotateReverse};
  animation-duration: 20s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  @media (min-width: 575px) {
    top: -0.5em;
  }

  @media (min-width: 775px) {
    top: -1em;
  }
`

const visualImage = css`
  position: absolute;
  top: 2em;
  right: 0;
  left: 0;
`
const inviteCard = css`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  background-color: ${colors.white};
  margin: 7.5em 0 0 15%;

  @media (min-width: 575px) {
    margin: 0;
  }
`
const inviteContent = css`
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  padding: 0 30px 30px 30px;

  @media (min-width: 575px) {
    padding: 0 60px 30px 60px;
  }
`

const inviteHeadline = css`
  font-size: 28px;
  position: relative;
  top: -25px;
  left: -16px;
  margin-bottom: 0;

  @media (min-width: 575px) {
    font-size: 32px;
  }
  @media (min-width: 775px) {
    font-size: 36px;
  }
`
const inviteLabel = css`
  font-family: ${fonts.monospace};
  font-size: 14px;
  color: ${colors.roseDark};
  margin-bottom: 20px;
`

const primaryInviteText = css`
  font-family: ${fonts.body};
  font-size: 24px;
  font-weight: 400;
  line-height: 1.3;
  text-align: center;
`

const secondaryInviteText = css`
  font-family: ${fonts.body};
  font-size: 20px;
  font-weight: 400;
  line-height: 1.3;
`

const inviteBody = css`
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 220px;
  margin: auto;
`

const inviteBodyText = css`
  font-size: 18px;
  color: ${colors.roseDark};
  text-align: center;
`

const IndexPage = () => (
  <Layout>
    <SEO title="Our Wedding" />
    <Header logo={false} />
    <div css={inviteLockup}>
      <div css={visualContainer}>
        <MikeyAriText css={visualText} />
        <MukeAriImage css={visualImage} />
      </div>
      <div css={inviteCard}>
        <h1 css={inviteHeadline}>We&rsquo;re Getting Married!</h1>
        <div css={inviteContent}>
          <span css={inviteLabel}>When</span>
          <h3 css={primaryInviteText}>
            October 11, 2021
            <br />5 o&rsquo;clock
          </h3>
          <span css={inviteLabel}>Where</span>
          <h3 css={primaryInviteText}>
            Avalon Hotel &amp; Bungalows
            <br />
            <span css={secondaryInviteText}>
              415 South Belardo Road
              <br />
              Palm Springs, CA 92262
            </span>
          </h3>
          <div css={inviteBody}>
            <p css={inviteBodyText}>
              Together with our families,
              <br />
              we invite you to join us
              <br />
              for the celebration of our love
            </p>
          </div>
        </div>
      </div>
    </div>
    <NextPage to="/our-story">Our Story</NextPage>
  </Layout>
)

export default IndexPage
